import React from 'react'
import {
  CardWrap,
  Card,
  Icon,
  Cta
} from './featurecard.stc'

const FeatureCard = ({ title, subhead, copy, ctaText, ctaLink, icon, currentPath, ...props }) => {

  const linkAnchor = currentPath.replace(/\/$/, '') + '#fid_' + ctaLink

  return (
    <CardWrap {...props}>
      <Card>
        {icon != null &&
          <Icon
            icon={icon.icon.asset.url}
            color={icon.iconColor.rgb}
            bg={icon.iconBackground.rgb}
          />}
        {title &&
          <h3>{title}</h3>}
        {subhead &&
          <h4>{subhead}</h4>}
        {copy &&
          <p>{copy}</p>}
        {ctaLink &&
          <Cta to={linkAnchor} data-scroll>{ctaText || 'Click Here'}</Cta>}
      </Card>
    </CardWrap>
  );
};

export default FeatureCard;