import React from 'react';
import FeatureCard from '../featurecard';
import { ListSection, ListWrapper } from './cardlist.stc';

const CardList = ({ cards, ...props }) => {
  return (
    <ListSection>
      <ListWrapper>
        {cards.map((card, i) => {
          return (
            <FeatureCard
              key={i}
              title={card.title}
              subhead={card.subtitle}
              copy={card.summary}
              ctaText="Learn More"
              ctaLink={card._key}
              icon={card.icon}
              {...props}
            />
          );
        })}
      </ListWrapper>
    </ListSection>
  );
};

export default CardList;
