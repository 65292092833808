import { device } from '../../theme'
import styled from 'styled-components';

export const ListSection = styled.section`
  padding: 50px 0;
  background: #f8f8f8;
`;

export const ListWrapper = styled.div`
  margin: 0 auto;
  padding: 25px 6.25vw;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;

  @media ${device.medium} {
    width: 100%;
  }
`;
