import React from 'react'
import DetailPanel from './panel'
import { ContentContainer } from './detail.stc'

const DetailList = ({ features, copyRef }) => {
  return (
    <ContentContainer>
      {features.map((detail, i) => {
        return (
          <DetailPanel
            key={detail._key}
            anchor={detail._key}
            i={i}
            parentTitle={detail.title}
            title={detail.feature.title}
            subtitle={detail.feature.subtitle}
            copy={copyRef[i].feature.copy}
            image={detail.feature.image.asset.fluid}
            alt={detail.feature.image.alt}
            ctaText={detail.feature.cta.copy}
            ctaPath={detail.feature.cta.path.page.slug.current}
          />
        );
      })}
    </ContentContainer>
  );
};

export default DetailList;
