import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { Link } from 'gatsby';
import { DetailWrap, DetailContent, DetailMedia} from './detail.stc'

const DetailPanel = ({ title, subtitle, copy, image, alt, ctaText, ctaPath, anchor }) => {
  const anchorId = 'fid_' + anchor;
  return (
    <DetailWrap id={anchorId}>
      <DetailContent>
        {title && <h4>{title}</h4>}
        {subtitle && <h5>{subtitle}</h5>}
        {copy && <BlockContent blocks={copy} />}
        {ctaText && ctaPath && (
          <Link to={`/${ctaPath}`} className="button">
            {ctaText}
          </Link>
        )}
      </DetailContent>
      <DetailMedia fluid={image} alt={alt} />
    </DetailWrap>
  );
};

export default DetailPanel;