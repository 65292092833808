import React from 'react';
import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import SVG from 'react-inlinesvg'
import { device } from '../../theme'

export const CardWrap = styled.div`
  /* width: ${props => props.cardBasis};
  padding: ${props => props.cardPadding}; */
  width: 33%;
  padding: 0 15px;

  @media ${device.medium} {
    width: 50%;
    padding: 5%;
  }
  @media ${device.small} {
    width: 100%;
    padding: 5%;
  }
`;

export const Card = styled.div`
  padding: 30px 30px;
  border: 1px solid #f8f8f8;
  background: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  text-align: center;

  h3 {
    margin-top: 50px;
    font-size: 1.07em;
    letter-spacing: 1.5px;
    color: #333;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.1;
  }

  h4 {
    font-size: 1em;
    letter-spacing: 1px;
    color: #555;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.1;
  }

  p {
    margin-top: 40px;
    font-size: .9em;
    color: #555;
    text-align: left;
  }

  .icon {
    transition: all 0.2s;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 22px 20px rgba(0, 0, 0, 0.15);
    .icon {
      transform: translateY(-3px) translateZ(3px) ;
      border: 0px solid white;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.45);
    }
  }
`;

const IconContainer = styled.div`
  width: 100px;
  height: 100px;
  margin: 5px auto;
  vertical-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(${props => props.r},${props => props.g},${props => props.b},${props => props.a});
  
  border: 5px solid white;
  border-radius: 0;

  box-shadow: 0 0 0 1px rgba(${props => props.r},${props => props.g},${props => props.b},${props => props.a});

  /* transition: border-color ease 0.5s; */
  transition: border ease 0.35s;

  :hover {
    border-color: rgba(${props => props.r},${props => props.g},${props => props.b},${props => props.a});
    border-width: 0;
  }
`;

const IconInline = styled(SVG)`
  width: 45px;
  height: auto;
  margin: 0 auto;

  path, g {
    fill: rgba(${props => props.r},${props => props.g},${props => props.b},${props => props.a});
  }
`;

export const Icon = ({ icon, color, bg }) => {
  return (
    <IconContainer
      r={bg ? bg.r : '17'}
      g={bg ? bg.g : '17'}
      b={bg ? bg.b : '17'}
      a={bg ? bg.a : '1'}
      className="icon"
    >
      <IconInline
        src={icon}
        r={color ? color.r : '235'}
        g={color ? color.g : '235'}
        b={color ? color.b : '235'}
        a={color ? color.a : '1'}
      />
    </IconContainer>
  );
};

export const Cta = styled(AnchorLink)`
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
  display: inline-block;
  padding: 8px 38px;
  background: #111;
  color: #ececec;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1.8em;
  border-radius: 0;
  transition: all 0.3s;
  border: 1px solid rgba(17, 17, 17, 0);

  &:hover {
    border: 1px solid rgba(17, 17, 17, 1);
    background: #fff;
    color: #111;
  }

  &:visited {
    color: #ececec;
  }
`;