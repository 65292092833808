import styled from 'styled-components';
import {device} from '../../theme'

export const ProjectSectionWrap = styled.section `
    background-color: ${props => props.bg};
    padding: 30px 0;
    @media ${device.medium}{
        padding: 0;
    }
    @media ${device.small}{
        /* padding-bottom: 10vw; */
    }
`;

export const ProjectTitleContainer = styled.header `
    display: inline-block;
    width: 100%;
    padding: 30px 0;
`;

export const ProjectCardWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;