import styled from "styled-components";
import { device } from "../../theme";
import Img from 'gatsby-image';

export const BodySectionWrap = styled.section`
  display: flex;
  padding: 0 12.5%;

  @media ${device.medium} {
    padding-left: 0;
    padding-right: 7.5%;
  }

  @media ${device.small} {
    padding: 0 8.75%;
    display: inline-block;
  }
`;

export const BodyContentContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 25px 50px;
  @media ${device.medium} {
    padding: 25px 0;
  }
  @media ${device.small} {
    width: 100%;
  }
`;

export const BodyTitleContainer = styled.header`
  width: 100%;
  padding: 30px 0;
  @media ${device.small} {
    text-align: center;
  }
`;

export const BodyCopy = styled.article`
  text-align: justify;
  @media ${device.medium} {
    padding: 25px 8%;
    width: 100%;
    padding-left: 0;
  }
`;

export const Accent = styled(Img)`
  flex-grow: 1;
  order: -1;
  min-height: 100%;
  margin: 0 50px;

  @media ${device.medium} {
    margin: 0 25px 0 0;
  }

  @media ${device.small} {
    display: none;
  }
`;