import React from 'react';
import Project from '../project';
import SectionTitle from '../shared/section-title'
import { ProjectTitleContainer, ProjectSectionWrap, ProjectCardWrap } from './project.stc';

const FeaturedProjects = ({ projects }) => {

  return (
    <ProjectSectionWrap>
      <ProjectTitleContainer>
        <SectionTitle title={'Related Projects'} />
      </ProjectTitleContainer>

      <ProjectCardWrap>
        {projects.map((data, i) => {
          let isEven = i % 2 === 0;
          return (
            <Project
              isEven={isEven}
              key={data.slug.current}
              slug={data.slug.current}
              summary={data._rawHomeSummary}
              image={data._rawImg}
              title={data.title}
              client={data.client}
              category={data.category}
            />
          );
        })}
      </ProjectCardWrap>
    </ProjectSectionWrap>
  );
};

export default FeaturedProjects;
